import React, { useEffect } from 'react';

import Paper from '@material-ui/core/Paper';

const DeckPage = () => {
  useEffect(() => {
    window.location.replace('https://about.behavery.com/login/?demo=1');
  }, []);

  return (
    <Paper>
      <h1>Y Combinator Demo</h1>
      <p>Redirecting ...</p>
    </Paper>
  );
};

export default DeckPage;
